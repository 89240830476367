<template>
  <div>
    <el-button type="primary" size="mini" @click="showExplanation">
      评分说明
    </el-button>

    <el-dialog
      title="评分说明"
      :visible.sync="dialogVisible"
      width="600px"
      append-to-body
    >
      <div class="explanation-content">
        <h4>一、浮动薪酬计算方法</h4>
        <p>
          项目组成员月度浮动薪酬 = 月度审核工时 × (年浮动工资 / 2086) ×
          月度评价系数
        </p>
        <p>注：测绘外业人员分母为2294</p>

        <h4>二、评价系数说明</h4>
        <p>1. 正常范围：0.7 - 1.2</p>
        <p>2. 一般范围：0.85 - 1.1</p>
        <p>3. 特殊情况：</p>
        <ul>
          <li>当评价系数低于0.85或高于1.1时，需说明具体理由</li>
          <li>
            当评价系数低于0.7或高于1.2时，需向部门经理或分管高管说明并审定
          </li>
        </ul>

        <h4>三、项目进度匹配原则</h4>
        <p>
          1.
          项目进度匹配或优于内部生产资金支出比例时，评价系数一般在0.7至1.2之间
        </p>
        <p>
          2.
          项目进度落后时，应调低评价系数，使项目进度与内部生产资金支出比例匹配
        </p>

        <h4>四、其他说明</h4>
        <p>1. 项目经理由部门经理进行评价，试行期间可由分管高管负责</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    showExplanation() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
    padding: 0 20px;
}
.explanation-content {
  h4 {
    margin: 15px 0 10px;
    color: #303133;
  }

  p {
    margin: 8px 0;
    line-height: 1.6;
    color: #606266;
  }

  ul {
    margin: 8px 0;
    padding-left: 20px;

    li {
      line-height: 1.6;
      color: #606266;
    }
  }
}
</style>
